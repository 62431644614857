<template>
  <div class="layouts">
    <div class="title">
        <div>
            <div class="title-left">
                运营通    
            </div>
        </div>
        <div>
            <span @click="tuClick()">退出登录</span>
        </div>
    </div>
    <div class="cont">
        <div class="nav">
            <el-menu
                :router="true"
                :default-active='rotUrl'
                class="el-menu-vertical-demo"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#1989fa">
                <div v-for="(value) in titleData" :key="value.name+value.path">
                    <component class="menu-item"
                            v-if="value.name"
                            :index="value.path"
                            :is="(value.children && value.children.length>0)?'el-submenu':'el-menu-item'">
                    <template slot="title" v-if="value.name">
                        <i :class="[value.icon]"></i>
                        <span>{{ value.name }}</span>
                    </template>
                    <template v-if="value.children&&value.children.length>0 && value.name">
                        <el-menu-item class="menu-item" v-for="(v,i) in value.children" :key="v.path+i" :index="v.path">
                        <i :class="[v.icon]"></i>
                        <span slot="title">{{ v.name }}</span>
                        </el-menu-item>
                    </template>
                    </component>
                </div>
            </el-menu>
        </div>
        <div class="cont-cont">
            <router-view></router-view>
        </div>
    </div>
  </div>
</template>

<script>
import { loginOut } from '../api/index'
export default {
    data(){
        return{
           titleData: JSON.parse(localStorage.getItem('menu_list')),
           rotUrl:'/k-gold'
        }
    },
    created(){
        this.rotUrl = this.$route.path
    },
    methods:{
        tuClick(){
            loginOut({}).then(er =>{
                if(er.return_code == 200){
                    localStorage.removeItem("Authorization");
                    localStorage.removeItem("company_list");
                    this.$router.replace('/login') 
                     window.localStorage.clear()
                    this.$message({
                        message: '退出成功',
                        type: 'success'
                    });
                }
            })
        },
        navClick(row){
            console.log(row)
        }
    }
};
</script>

<style lang="scss" scoped>
    .layouts {

        .title {
            padding: 0px 30px;
            height: 60px;
            background: #1989fa;
            display: flex;
            align-items: center;
            color: #fff;
            .title-left{
                font-size: 18px;
            }
            >div{
                width: 50%;
                &:nth-child(1){
                    display: flex;
                    align-items: center;
                }
                &:nth-child(2){
                    text-align: right;
                    >span{
                        font-size: 16px;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                   
                }
            }
        }
        .cont {
            display: flex;
            height: calc(100vh - 60px);
            .nav {
                height: 100%;
            }
        }
    }

    // 侧边栏
    .el-menu {
        width: 200px;
        height: 100%;
    }
    .cont-cont{
        width: calc(100% - 200px);
        padding: 0px 24px;
        overflow: auto;
    }
    // 头部
    .el-popover .navli{
        background: red;
    }
</style>
